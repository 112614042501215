<template>
  <div class="w-100">
    <div class="d-flex justify-content-start py-2">
      <h5>1:1 문의</h5>
    </div>
    <b-table-simple v-if="!pending">
      <b-tbody
        v-if="questions.length"
      >
        <b-tr
            v-for="que in questions"
            :key="`answer-${que._id}`"
        >
          <b-td>
            <div>
              <p class="my-0" style="font-size: 0.7rem;">
                {{ que._id }}
              </p>
            </div>
          </b-td>
          <b-td>
            <div class="d-block">
              <p class="text-truncate my-0" style="max-width: 100px;font-size: 0.7rem;">
                {{ que.content }}
              </p>
            </div>
          </b-td>
          <b-td>
            <p class="my-0" style="font-size: 0.7rem;">
              {{ que.regDate }}
            </p>
          </b-td>
          <b-td>
            <div class="d-block">
              <p class="text-truncate my-0" style="max-width: 100px;font-size: 0.7rem;">
                {{ que.answerContent }}
              </p>
            </div>
          </b-td>
          <b-td>
            <p class="my-0" style="font-size: 0.7rem;">
              {{ que.answerDate ? new Date(que.answerDate).toLocaleString() : '답변 전' }}
            </p>
          </b-td>
          <b-td>
            <div>
              <b-button
                size="sm"
                squared
                v-on:click="goAnswer(que._id)"
              >
                <font-awesome-icon
                  icon="edit"
                />
              </b-button>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody v-else>
        <b-tr>
          <b-td colspan="5">
            NO DATA
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div v-else>
      <b-spinner
          variant="danger"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  props: {
    selectedId: String
  },
  data: () => ({
    pending: false,
    page: 1,
    questions: []
  }),
  created () {
    (async () => {
      console.log(this.selectedId)
      await this.load()
    })()
  },
  methods: {
    async load () {
      this.pending = true
      try {
        const { data } = await this.axios({
          url: `/admin/question/specific/${this.selectedId}`,
          method: 'GET',
          params: {
            page: this.page
          }
        })
        const { result, questions } = data
        console.log(data)
        if (result) this.questions = questions
      } catch (e) {
        console.log(e)
      }
      this.pending = false
    },
    goAnswer (_id) {
      const path = `/answer/${_id}`
      this.$router.push({
        path
      })
    }
  }
}
</script>

<style scoped>
th, td {
  font-size: 0.8rem;
  vertical-align: middle;
}
</style>
